<template>
	<div id="AboutList">
		<div class="about">
			<router-link class="about-i flc text-none" :to="{ name: 'about_detail', query: { id: item.id}}"
				v-for="(item,index) in companyList" :key="index">
				<el-image class="about-i-pic" :src="item.cover" :fit="'cover'">
				</el-image>

				<div class="about-i-r">
					<div class="about-i-r-t">
						{{item.title}}
					</div>
					<div class="about-i-r-b line-two">
						{{item.detail}}
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
	import {
		companycompanyList
	} from "../../utils/index.js"

	export default {
		name: "AboutList",
		data() {
			return {
				companyList: []
			}
		},
		created() {
			this.companycompanyList()
		},
		methods: {
			// 企业列表
			companycompanyList() {
				companycompanyList().then(res => {
					// console.log("企业列表", res);
					this.companyList = res.data
				})
			},
		}
	}
</script>

<style>
</style>
