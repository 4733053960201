<template>
	<div ><!-- id="About" -->
		<!-- <Navigation /> -->
		<div id="Navigation">
					<div class="navigation-bgc">
						<div class="navigation flcs">
							<div class="navigation-l flc cursor" @mouseover="mouseoverClick" @mouseleave="mouseleaveClick"
								:style="{backgroundColor: isPop ? '#ffffff' : '#f8f8f8', border : isPop ? '1px solid #EEEEEE' : '1px solid #f8f8f8' }">
								<img class="navigation-l-l" src="../../assets/img/location.png">
								<div class="navigation-l-r">
									{{address}}
								</div>
				
								<div class="navigation-l-p" v-if="isPop">
									<div class="navigation-l-p-t" style="border-bottom: 1px solid #D5D5D5;">
										<div class="navigation-l-p-t-i cursor" v-for="(item,index) in cityList" :key="index"
											:style="{backgroundColor: chooseIndex === index ? '#D80000' : '#ffffff' , color: chooseIndex === index ? '#ffffff' : '#333333'}"
											@click="chooseClick(index,item)">
											{{item.name}}
										</div>
									</div>
									<div class="navigation-l-p-t">
										<div class="navigation-l-p-t-i cursor"
											:style="{backgroundColor: chooseTwoIndex === index2 ? '#D80000' : '#ffffff' , color: chooseTwoIndex === index2 ? '#ffffff' : '#333333'}"
											v-for="(item2,index2) in cityList[chooseIndex].child" :key="index2"
											@click="chooseTwoClick(index2,item2)">
											{{item2.name}}
										</div>
									</div>
								</div>
							</div>
							<div class="navigation-r flc">
								
								<div class="navigation-r-a">
									你好,欢迎光临物卖空
								</div>
								<router-link class="navigation-r-b text-none" :to="'login'"  v-if="uid==null">
									登录
								</router-link>
								<div v-else style="display: flex;">
									<div class="navigation-r-b text-none" >
										{{nickname}}
									</div>
									<div class="navigation-r-d cursor"   @click="quit">
										退出
									</div>
								</div>
								
								<!-- <router-link class="navigation-r-c text-none" :to="'login'">
									注册
								</router-link> -->
								<div class="navigation-r-d cursor"  @click="clickD(1)" >
									我的订单
								</div>
								<!-- <div class="navigation-r-d">
									我的订单
								</div -->
								<div class="text-none cursor"  @click="clickD(2)">
									<div class="navigation-r-f">
										用户中心
									</div>
								</div>
							</div>
						</div>
					</div>
				
					<div class="navigation-box">
						<div class="navigation-box-h flc">
							<img class="navigation-box-h-l" src="../../assets/img/logo.png" @click="Login">
							<!-- 宝贝，店铺  搜索设置 -->
							<div class="flc" style=" border: 2px solid #D80000;border-radius: 6px;margin-left: 52px;">
								<div class="navigati-click-box" @click="shoptypeclick()">
									<div class="navigati-click">
										<div>{{shopnames}}</div>
										<img class="click-imgs" src="../../assets/img/right1.png">
									</div>
									<div class="navigati-click1" v-if="shoptypesshow==true" @click="Stypeclick()">
										<div style="margin-right: 40px;">{{shopnames1}}</div>
									</div>
								</div>
							
								<input class="navigation-box-h-i" 
								v-model="condition" :placeholder="shopplaceholder" @keyup.enter="submit"/>
								<div class="navigation-box-h-c flcc"  @click="submit" 
																			@keyup.enter="submit">
									<img class="navigation-box-h-c-l" src="../../assets/img/search.png">
									<div class="navigation-box-h-c-r text-none">
										搜索
									</div>
								</div>
								
							</div>
		
							<div class="navigation-box-h-r flcc cursor" @click="clickD(3)">
								<img class="navigation-box-h-r-l" src="../../assets/img/car.png">
								<div class="navigation-box-h-r-r">
									购物车
								</div>
							</div>
							
							<div class="navigation-box-text flc">
								<div class="" style="display: flex;"  v-for="(item,index) in Llist" :key="index" >
									<div class="navigation-box-text-i" @click="Dinaji(index)"
									style="cursor: pointer;" >
										{{item}}
									</div>
									<!-- <div class="navigation-box-text-i">
										手套
									</div>
									<div class="navigation-box-text-i">
										零件
									</div> -->
								</div>
							</div>
							
						</div>
					</div>
				</div>
		<!-- <ClassifyChoose></ClassifyChoose> -->
		<div id="show-back">
			
		
		<SideNavigation></SideNavigation>
		<!-- <Breadcrumb :navigationList="navigationList"></Breadcrumb> -->

		<div id="AboutList">
			<div class="about"
			>
				<div style="height: 80px;width: 1200px;">
					
				</div>
				<div style="min-height: 600px;display: flex;flex-wrap: wrap;justify-content: space-between;" >
					<div class="stores-box" v-for="(item,index) in companyList" :key="index">
						<div class="stores-head">
							<el-image  class="stores-img" :src="item.cover"></el-image>
							<div class="stores-text">
								{{item.title}}
							</div>
							<router-link class="stores-buttom"  :to="{name:'stores_home',query: { AD: item.shopId}}">
								进入店铺
							</router-link>
						</div>
						<div class="stores-R" v-if="item.goodsResponse.length>3">
							<div class="stores-child" >
								<router-link :to="{name:'shop_detail',query:{id:item.goodsResponse[0].id}}" style="text-decoration: none;" >
								<img class="stores-child-img" :src="item.goodsResponse[0].cover" >
								<div class="stores-child-text">
									{{item.goodsResponse[0].title||''}}
								</div>
								<div class="stores-child-text1">
									¥{{item.goodsResponse[0].price||''}}
								</div>
								</router-link>
							</div>
							<div class="stores-child" >
								<router-link :to="{name:'shop_detail',query:{id:item.goodsResponse[1].id}}" style="text-decoration: none;" >
								<img class="stores-child-img" :src="item.goodsResponse[1].cover" >
								<div class="stores-child-text">
									{{item.goodsResponse[1].title||''}}
								</div>
								<div class="stores-child-text1">
									¥{{item.goodsResponse[1].price||''}}
								</div>
								</router-link>
							</div>
							<div class="stores-child" >
								<router-link :to="{name:'shop_detail',query:{id:item.goodsResponse[2].id}}" style="text-decoration: none;" >
								<img class="stores-child-img" :src="item.goodsResponse[2].cover" >
								<div class="stores-child-text">
									{{item.goodsResponse[2].title||''}}
								</div>
								<div class="stores-child-text1">
									¥{{item.goodsResponse[2].price||''}}
								</div>
								</router-link>
							</div>
						</div>
					</div>
				
				</div>
			</div>
		</div>
		
		
		
		<div class="pagingaClass">
			<el-pagination class="pagingaClass-i" background layout="prev, pager, next" :total="total"
				@current-change="handleCurrentChange">
			</el-pagination>
		</div>
		</div>
		<Footer class="dibu"></Footer>
	</div>
</template>

<script>
	import Navigation from "../common/navigation.vue"
	//import ClassifyChoose from "../common/classify_choose.vue"
	//import Breadcrumb from "../common/breadcrumb.vue"
	import SideNavigation from "../common/side_navigation.vue"
	import AboutList from "./about_list.vue"
	import Footer from "../common/footer.vue"
	
	import {
		citygetAllList,
		companycompanyList
	} from "../../utils/index.js"
	export default {
		name: "About",
		components: {
			Navigation,
			//ClassifyChoose,
			//Breadcrumb,
			SideNavigation,
			AboutList,
			Footer,
		},
		data() {
			return {
				navigationList: [{
					name: "首页",
					url: "/",
					type:0
				}, {
					name: "企业介绍",
					url: "about",
					type:0
				}],
				isPop: false,
				chooseIndex: 0,
				chooseTwoIndex: "",
				condition: "",
				cityList: [],
				address: "",
				uid:'',
				nickname:'',
				Llist:[],
				aDDID:0,
				companyList:[],
				total:0,
				page:1,
				shoptypes:1,//选择店铺 宝贝类型
				shoptypesshow:false,
				shopnames:'店铺',//选择店铺 宝贝名字
				shopnames1:'商品',//未选择店铺 宝贝名字
				shopplaceholder:'搜索前请先选择想要购买物品的区域',//input的placeholde显示
				cityId:"",
				provinceId:'',
			}
		},
		created() {
			
			let str = localStorage.getItem("$local") || ''
			if (str != '') {
				let arr = str.split(',')
				
				if(arr.length==2){
					for (var i = 0; i < arr.length; i++) {
						if (i == 0) {
							//console.log("省",arr[i])
							this.provinceId=arr[i]
							//this.fromData.province = arr[i]
						} else {
							//console.log("市",arr[i])
							this.cityId=arr[i]
							//this.fromData.city = arr[i]
						}
					}
				}else{
					this.provinceId=arr[0]
				}
				
			}
			
			if (this.conditions) {
				this.condition = this.conditions
			}
			if (localStorage.getItem("$citygetAll")) {
				this.cityList = JSON.parse(localStorage.getItem("$citygetAll"))
				  this.cityList.forEach((e)=>{
					  //console.log("城市",e.child)
						if(e.id==this.aDDID){
							this.address=e.name
							//console.log("aaa",e.name)
						}
				})
			} else {
				this.citygetAllList()
			}
			
			
			if(localStorage.getItem("$local")){
				 let add=localStorage.getItem("$local")
				 if(add.split(",").length>=2){
					  this.aDDID=add.split(",")[1]
					 this.cityList.forEach((e)=>{
					 	 // console.log("城市",e.child)
					 		if(e.id==add.split(",")[0]){
					 			e.child.forEach((es)=>{
									if(es.id==this.aDDID)
									this.address=es.name
								})
					 			//console.log("aaa",e.name)
					 		}
					 })
				 }else{
					 this.aDDID=add.split(",")[0]
					 this.cityList.forEach((e)=>{
					 	  //console.log("城市",e.child)
					 		if(e.id==this.aDDID){
					 			this.address=e.name
					 			//console.log("aaa",e.name)
					 		}
					 })
				 }
			}
			let Lli=localStorage.Llist
			if(Lli){
				this.Llist=JSON.parse(Lli)
			}
			
			if (this.$route.query.condition) {
				this.condition = this.$route.query.condition.toString()
				this.companycompanyList(this.condition)
			}else{
				this.companycompanyList()
			}
			
			
			// let add=localStorage.getItem("$local")
			
			
			
			this.uid=localStorage.getItem("uid")
			this.nickname=localStorage.getItem("nickname")
		},
		watch: {
			conditions(val) {
				this.condition = val
				
			}
		},
		methods:{
			shoptypeclick(){
				this.shoptypesshow==false?this.shoptypesshow=true:this.shoptypesshow=false
				//this.shoptypesshow!=this.shoptypesshow
				console.log(this.shoptypesshow)
			},
			Stypeclick(){
				this.shopnames=='商品'?this.shopnames="店铺":this.shopnames="商品"
				//this.shopnames=='宝贝'?localStorage.setItem("shopnames",'店铺'):localStorage.setItem("shopnames",'宝贝')
				this.shopnames1=='店铺'?this.shopnames1="商品":this.shopnames1="店铺"
				this.shoptypes==0?this.shoptypes=1:this.shoptypes=0
				//this.shoptypes==1?localStorage.setItem("shoptypes",0):localStorage.setItem("shoptypes",1)
				this.shopplaceholder=='搜索前请先选择想要购买物品的区域'?this.shopplaceholder='搜索前请先选择想了解的公司、店铺的区域':this.shopplaceholder='搜索前请先选择想要购买物品的区域'
				
				//window.location.reload()
			},
			Login(){
				this.$router.push({
					path:"/"
				})
			},
			Dinaji(index){
				this.condition=''
				this.condition=this.Llist[index]
			},
			submit() {
				//console.log(this.shoptypes)
				if(this.shoptypes==0){
					this.$router.push({
						path:'shop',
						query:{condition:this.condition,shoptypes:this.shoptypes}
					})
				}else{
					this.companycompanyList(this.condition)
				}
				//this.companycompanyList(this.condition)
				//判断是否有重复
				var index = this.Llist.findIndex((ele) => {
					return ele == this.condition;
			    });
			    //如果有的话就删除重复
				if (index != -1) {
					this.Llist.splice(index, 1);
			    }
				if(!this.condition==''){
					//向数组第一位添加
					this.Llist.unshift(this.condition);
				}
			    //如果数组长度大于4 就删除最后一项
				if (this.Llist.length > 6) {
					this.Llist.splice(7, 1);
			    }
			    // 本地存储历史记录数组
			    localStorage.Llist = JSON.stringify(this.Llist);
				//this.condition=''
				
			},
			clickDs(){
				this.Llist.unshift(this.condition)
				localStorage.setItem("Llist",this.Llist)
				this.$router.push({
					path:'shop',
					query:{condition:this.condition}
				})
			},
			quit(){
				this.$router.push({
					path:'/',
				})
				// 清除状态保持
				window.localStorage.clear()
				// 状态保持清除后刷新页面
				window.location.reload()
				
			},
			clickD(index){
				let uid=localStorage.getItem('uid')
				if(uid!==null){
					if(index==1){
						this.$router.push({
							path:'/userinfo',
							query:{type:2}
						})
					}else if(index==2){
						this.$router.push({
							path:'/userinfo',
							query:{type:0}
						})
					}else if(index==3){
						this.$router.push({
							path:'/car'
						})
					}
				}else{
					this.$router.push({
						path:'/login',
					})
				}
			},
			// 获取省市
			citygetAllList() {
				citygetAllList().then(res => {
					// console.log("获取省市", res);
					this.cityList = JSON.parse(res.data)
					localStorage.setItem("$citygetAll", res.data)
					this.address = this.cityList[0].name
					
					localStorage.setItem("$local", this.cityList[0].id)
				})
			},
			chooseClick(index, item) {
				this.chooseIndex = index
				this.address = item.name
				this.chooseTwoIndex = ""
				let str = item.id
				//console.log(str)
				localStorage.setItem("$local", str)
				this.$emit("localClick")
			},
			chooseTwoClick(index, item) {
				this.chooseTwoIndex = index
				this.address = item.name
				//console.log(item.id)
				let str = localStorage.getItem("$local") + ',' + item.id
				localStorage.setItem("$local", str)
				
				this.$emit("localClick")
			},
			
			mouseoverClick() {
				this.isPop = true
			},
			mouseleaveClick() {
				this.isPop = false
				location.reload() 
			},
			searchClick() {
				//console.log("1111111111111111111111111111")
				this.$emit("searchClick", this.condition)
				//判断是否有重复
				var index = this.Llist.findIndex((ele) => {
					return ele == this.condition;
				});
				//如果有的话就删除重复
				if (index != -1) {
					this.Llist.splice(index, 1);
				}
				if(!this.condition==''){
					//向数组第一位添加
					this.Llist.unshift(this.condition);
				}
				//如果数组长度大于4 就删除最后一项
				if (this.Llist.length > 6) {
					this.Llist.splice(7, 1);
				}
				// 本地存储历史记录数组
				localStorage.Llist = JSON.stringify(this.Llist);
			},
			handleCurrentChange(val) {
				//console.log(`当前页: ${val}`);
				this.page = val
				this.companycompanyList()
			},
			// 企业列表
			companycompanyList(condition) {
				companycompanyList({
					condition:condition,
					cityId:this.cityId,
					provinceId:this.provinceId,
					page: this.page,
					size: 20,
				}).then(res => {
					
					this.companyList = res.data.records
					this.total = res.data.total * 1
					// console.log("企业列表", this.companyList);
				})
			},
		}
	}
</script>

<style lang="less">
	@import url("about.less");
	#show-back{
		//height: 600px ;
		weight:1200px;
		margin: 0 auto ;
		background: url(../../assets/img/图层%201982.png);
		background-size: 1200px 600px;
		background-repeat: no-repeat;
		background-attachment:fixed;
		background-position:345px 170px;
	}
	.pagingaClass{
		margin:30px auto;
		width: 1200px;
		display: flex;
		justify-content: center;
	}
	.dibu{
		position: relative;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
	}
	.stores-box{
		width: 590px;
		height: 247px;
		background: #FFFFFF;
		display: flex;
		margin-top: 20px;
		.stores-R{
			margin-top: 30px;
			margin-left: 20px;
			display: flex;
			.stores-child{
				margin-right: 10px;
				.stores-child-img{
					width: 110px;
					height: 110px;
					background: #FFFFFF;
					border: 1px solid #DBDBDB;
					border-radius: 10px;
				}
				.stores-child-text{
					width: 110px;
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 500;
					color: #333333;
					word-break: break-all;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
				.stores-child-text1{
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #D80000;
				}
			}
		}
		.stores-head{
			width: 200px;
			height: 247px;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: linear-gradient(180deg, #C8E5FD 0%, #FFFFFF 100%);
			.stores-img{
				margin-top: 28px;
				width: 100px;
				height: 100px;
			}
			.stores-text{
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
				margin: 20px 0;
				width: 180px;
				height: 80px;
				text-align: center;
				word-break: break-all;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
			.stores-buttom{
				width: 100px;
				height: 30px;
				text-decoration-line: none;
				background: #FFFFFF;
				border: 1px solid #D80000;
				border-radius: 15px;
				font-size: 16px;
				line-height: 30px;
				text-align: center;
				font-family: PingFang SC;
				font-weight: bold;
				color: #D80000;
			}
		}
	}
</style>
